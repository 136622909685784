// store.js
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    windowWidth: window.innerWidth // 初始窗口宽度
  },
  mutations: {
    // 定义名为 "updateWindowWidth" 的 mutation
    updateWindowWidth (state, newWidth) {
      state.windowWidth = newWidth
    }
  },
  actions: {
    // 可选的 actions，如果需要的话
  },
  getters: {
    // 可选的 getters，如果需要的话
  }
})

export default store
