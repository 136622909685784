<template>
  <div :class="['mabout', lang === 'en' ? 'en-block' : '']">
    <MobileHeader></MobileHeader>
    <div class="banner">
      <div class="banner_left">
        <div class="product_title">{{ $t('about.aboutTitle') }}</div>
        <div class="product_subtitle">
         {{  $t('about.aboutSubTitle')}}
        </div>
      </div>
    </div>
    <div class="map">
      <div class="center">
        <img v-if="lang==='zh'" src="../../assets/image_mobile/map_diplay_app@2x.png" alt="">
        <img v-else src="../../assets/image_mobile/map_diplay_en_app@2x.png" alt="">
      </div>
    </div>
    <div class="service">
      <div class="center">
        <div class="service_title">{{  $t('home.service') }}</div>
        <div class="service_content">
          <div class="service_subtitle">
           {{ $t('home.serviceSub') }}
          </div>
          <div class="service_img">
            <img
              src="../../assets/image_mobile/home_img_cs_app@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="service_forword">
          <button> {{ $t('home.forword') }}</button>
        </div>
      </div>
    </div>
    <MobileFooter></MobileFooter>
  </div>
</template>

<script>
export default {
  components: {
    MobileHeader: () => import('@/components/mobile-header.vue'),
    MobileFooter: () => import('@/components/mobile_footer.vue')
  },
  data () {
    return {
      lang: sessionStorage.getItem('lang') || 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
.mabout{
    .banner {
    width: 100%;
    height: 368px;
    background-image: url('../../assets/image_mobile/about_bg1_app@2x.png');
    background-repeat: no-repeat;
    padding-top: 104px;
    background-position: 0 104px;
    background-size: contain;
    .banner_left {
      margin-left: 32px;
      padding-top: 66px;
      color: #fff;
      .product_title {
        width: 244px;
        font-size: 36px;
        line-height: 40px;
      }
      .product_subtitle {
        margin-top: 16px;
        width: 442px;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  .map{
    .center{
      padding: 44px 32px 0 32px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .service {
    margin-top: 70px;
    .center {
      margin-left: 32px;
      margin-right: 32px;
      text-align: center;
      .service_title {
        font-size: 36px;
        line-height: 40px;
        color: #333;
        font-weight: bold;
        text-align: left;
      }
      .service_content {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .service_subtitle {
          width: 334px;
          font-size: 24px;
          margin-top: 14px;
          text-align: left;
          color: #999;
        }
        .service_img {
          width: 196px;
          height: 136px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .service_forword {
        margin: 0 auto;
        margin-top: 44px;
        > button {
          width: 554px;
          height: 92px;
          border-radius: 16px;
          background-color: #7665ff;
          text-align: center;
          font-size: 28px;
          color: #fff;
        }
      }
    }
  }
}
.en-block{
  .banner {
    .banner_left{
       padding-top: 20px;
      .product_title{
        width: 342px;
        line-height: 40px;
        font-size: 36px;
      }
      .product_subtitle{
        width: 442px;
        line-height: 24px;
        font-size: 20px;
      }
    }
  }
}
</style>
