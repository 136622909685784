export default {
  home: {
    home: '首页',
    product: '产品',
    develop: '开发者',
    about: '关于我们',
    lang: '中文',
    homeTitle: '成为您的全球支付伙伴',
    homeSubTitle:
      '为您在拉丁美洲开展支付业务提供一切可靠、安全和高效的解决方案。',
    start: '现在开始',
    easier: '更简单',
    faster: '更快速',
    safer: '更安全',
    commit: '致力于让支付',
    commitSub: '更便捷、更迅速、更安全',
    Traditional: '传统支付',
    TraditionalText1: '人为错误更多',
    TraditionalText2: '支付流程更长',
    TraditionalText3: '结算周期更长',
    TraditionalText4: '欺诈交易频发',
    cashy: 'Cashy支付',
    cashyText1: '支付流程更短',
    cashyText2: '人为错误减少',
    cashyText3: '结算流程更快',
    cashyText4: '保障资金安全',
    integrate: '一次整合多种支付',
    integrateSub: '提供整合接口，一次接入即可便捷满足多种支付需求',
    integrateText1: 'API收款',
    integrateText2: '个性化收银台',
    integrateText3: '精准付款',
    account: '创建账户',
    access: '分配API权限',
    test: '测试联调',
    production: '正式投产',
    merchant: '可视化后台管理业务',
    merchantSub: '一站式可视化后台，统览交易数据，轻松管理业务',
    merchantTitle1: '发起提现',
    merchantSubTitle1: '在后台发起提现申请',
    merchantTitle2: '运营审批',
    merchantSubTitle2: '运营会在第一时间审批',
    merchantTitle3: '打款到账',
    merchantSubTitle3: '审批通过后即刻打款到账',
    service: '7*24 小时客户服务',
    serviceSub: '7*24小时专属客服在线支持您的任何诉求,响应及时、服务优质',
    forword: '期待您选择Cashy成为您的支付伙伴!',
    contact: '联系我们',
    payIn: '收款产品',
    payOut: '付款产品',
    startNow: '开始接入',
    signature: '签名规范',
    Support: '国家列表',
    email: '邮箱'
  },
  product: {
    productTitle: '收款产品',
    productSubTitle:
      '作为您全球的支付合作伙伴，Cashy提供收款和付款全套支付解决方案',
    payInProduct: '收款产品',
    payInProductSubTitle:
      '多元收款产品，Cashy在每个国家都提供了多种流行的支付方式',
    payInProductTitle1: 'Bank Transfer',
    payInProductTitle2: 'Virtual Account',
    payInProductTitle3: 'QR Code',
    payInProductTitle4: '现金支付',
    payInProductTitle5: '电子钱包',
    payOutProduct: '付款产品',
    payOutProductSubTitle:
      '高效付款，保证您的资金安全，付款至个人、企业。在印尼，Cashy还支持换汇转账'
  },
  about: {
    aboutTitle: '公司简介',
    aboutSubTitle:
      'Cashy坚信支付改变业务，支付创造机会。Cashy致力于提供稳定、可信赖、高性价比的支付服务，在多个行业为客户提供定制化支付解决方案；截止目前，已在全球服务超过10个国家和地区。'
  },
  map: {
    Mexico: '墨西哥',
    Colombia: '哥伦比亚',
    Peru: '秘鲁',
    Chile: '智利',
    Indonesia: '印度尼西亚',
    Brazil: '巴西'
  }
}
