<template>
  <div v-if="isPC">
    <Pc />
  </div>
  <div v-else>
    <Mobile />
  </div>
</template>

<script>
import Pc from './Pc'
import Mobile from './Mobile'
export default {
  components: {
    Pc,
    Mobile
  },
  data () {
    return {
      isPC: true // 默认为PC端
    }
  },
  watch: {
    // 监听窗口宽度变化
    '$store.state.windowWidth' (newWidth) {
      this.isPC = newWidth > 750 || this.$deviceType === 'browser'
    }
  },
  mounted () {
    // 初始化时判断窗口宽度
    this.isPC =
      this.$store.state.windowWidth > 750 || this.$deviceType === 'browser'
    // 监听窗口大小变化
    window.addEventListener('resize', this.handleWindowResize)
  },
  methods: {
    // 窗口大小变化时触发的方法
    handleWindowResize () {
      this.$store.commit('updateWindowWidth', window.innerWidth)
    }
  }
}
</script>

<style></style>
