<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.container {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
