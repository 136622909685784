export default {
  home: {
    home: 'Home',
    product: 'Product',
    develop: 'Developer',
    about: 'About Us',
    lang: 'English',
    homeTitle: 'To be your global payment partner',
    homeSubTitle: 'Provide one-stop payment solution',
    start: 'Start now',
    easier: 'Easier',
    faster: 'Faster',
    safer: 'Safer',
    commit: 'Commit to make payment',
    commitSub: 'Easier、Faster、Safer',
    Traditional: 'Tradition Payment',
    TraditionalText1: 'Longer process to pay',
    TraditionalText2: 'More human errors',
    TraditionalText3: 'Longer for settlement',
    TraditionalText4: 'Frequent fraud',
    cashy: 'Cashy Payment',
    cashyText1: 'Less human errors',
    cashyText2: 'No fraud and save money',
    cashyText3: 'Less steps to finish payment',
    cashyText4: 'Faster for process and settlement',
    integrate: 'Integrate once to get multiple payment products',
    integrateSub: 'You only need to integrate with Cashy once',
    integrateText1: 'API Integration',
    integrateText2: 'Tailored Paylink',
    integrateText3: 'Secure Payou',
    account: 'Create Account',
    access: 'Generate API access',
    test: 'Integrate and Test',
    production: 'Production',
    merchant: 'Merchant dashboard to manage your business',
    merchantSub:
      'Get details on one-stop merchant dashboard to check your transactions and manage your business',
    merchantTitle1: 'Request withdra',
    merchantSubTitle1: 'Request a withdrawal on merchant dashboard',
    merchantTitle2: 'Operator approve',
    merchantSubTitle2:
      'After verifying, operator will approve it as soon as possible',
    merchantTitle3: 'Execute',
    merchantSubTitle3:
      'Once approved and executed, merchants can receive money instantly',
    service: '7*24 Hours Customer Service',
    serviceSub:
      'Promise to provide 7*24 hours customer service, Support you anytime when you need us',
    forword: 'Looking forward to having you choose Cashy to be your partner!',
    contact: 'Contact Us',
    payIn: 'Payin',
    payOut: 'Payout',
    startNow: 'Start Now',
    signature: 'Signature',
    Support: 'Support',
    email: 'Email'
  },
  product: {
    productTitle: 'Product & Service',
    productSubTitle:
      'As your global payment partner, Cashy provides payment solution with Payin and Payout for you',
    payInProduct: 'Payin Product',
    payInProductSubTitle:
      'Cashy supports multiple payin products which are popular in each country',
    payInProductTitle1: 'Bank Transfer',
    payInProductTitle2: 'Virtual Account',
    payInProductTitle3: 'QR Code',
    payInProductTitle4: 'Cash',
    payInProductTitle5: 'e-Wallet',
    payOutProduct: 'Payout Product',
    payOutProductSubTitle:
      'Cashy will assure secure payout service. You can pay to individual and company. In Indonesia, we also support remittance'
  },
  about: {
    aboutTitle: 'Cashy Payment',
    aboutSubTitle:
      'Cashy was founded based on the belief that payments change business and create opportunity. Until now, Cashy has served merchants from more than 10 countries and the number keeps growing. Cashy commits to providing stable, reliable and pricing-competitive payment services. With tailored solution for different industries, we hope users can pay anytime, anywhere without concerns.'
  },
  map: {
    Mexico: 'Mexico',
    Indonesia: 'Indonesia',
    Colombia: 'Colombia',
    Peru: 'Peru',
    Chile: 'Chile',
    Brazil: 'Brazil'
  }
}
