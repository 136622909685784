<template>
  <div :class="['about', lang === 'en' ? 'en-block' : '']">
    <WebHeader></WebHeader>
    <div class="banner">
     <div class="center">
      <div class="banner_title" data-aos="slide-up" data-aos-duration="500">{{ $t('about.aboutTitle') }}</div>
      <div class="banner_desc" data-aos="slide-up" data-aos-duration="500">{{  $t('about.aboutSubTitle')}}</div>
     </div>
    </div>
    <div class="map">
      <div class="center">
        <img src="../../assets/image/map_diplay@2x.png" alt="">
      </div>
    </div>
    <div class="contact">
      <div class="center">
        <div class="contact_top">
          <div class="contact_left">
            <div class="contact_title" data-aos="slide-up" data-aos-duration="500">{{  $t('home.service') }}</div>
            <div class="contact_desc" data-aos="slide-up" data-aos-duration="500">
              {{ $t('home.serviceSub') }}
            </div>
          </div>
          <div class="contact_right" data-aos="slide-up" data-aos-duration="500">
            <img src="../../assets/image/home_img_cs@2x.png" alt="" />
          </div>
        </div>
        <div class="caontact_bottom" data-aos="slide-up" data-aos-duration="500">
          <button> {{ $t('home.forword') }}</button>
        </div>
      </div>
    </div>
    <WebFooter></WebFooter>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {
    WebHeader: () => import('@/components/web-header.vue'),
    WebFooter: () => import('@/components/web-footer.vue')
  },
  data () {
    return {
      lang: sessionStorage.getItem('lang') || 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  .banner {
    width: 100%;
    height: 368px;
    background: url('../../assets/image/about_bg1@2x.png') no-repeat center;
    background-size: 100% 100%;
    .center{
      width: 450px;
      margin: 0 auto;
      .banner_title{
      padding-top: 89px;
      font-size: 26px;
      font-weight: bold;
      line-height: 30px;
      color: #fff;
    }
    .banner_desc{
      width: 172px;
      line-height: 11px;
      font-size: 7px;
      font-weight: 500;
      color: #fff;
      margin-top: 8px;
    }
    }

  }
  .map{
    .center{
      width: 450px;
      margin: 35px auto 0;
        >img{
          width: 100%;
          height: 100%;
        }
    }
  }
  .contact {
    margin-top: 41px;
    .center {
      width: 450px;
      margin: 0 auto;
      .contact_top {
        display: flex;
        justify-content: space-between;
        .contact_left {
          > div:nth-child(1) {
            font-size: 14px;
            font-weight: bold;
            color: #333;
            padding-top: 41px;
          }
          > div:nth-child(2) {
            font-size: 7px;
            color: #999;
            font-weight: 500;
            margin-top: 13px;
          }
        }
        .contact_right {
          width: 185px;
          height: 123px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .caontact_bottom {
       text-align: center;
        > button {
          width: 374px;
          height: 39px;
          background-color: #7665ff;
          border-radius: 10px;
          margin: 29px auto 35px;
          font-size: 13px;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}
.en-block{
  .contact{
    .center{
      .caontact_bottom{
        > button{
          width: 421px;
        }
      }
    }
  }
}
</style>
