// import Cookies from 'js-cookie'
import VueI18n from 'vue-i18n'
import en from './en-US'
import zh from './zh-CN'
import Vue from 'vue'
Vue.use(VueI18n)

const messages = {
  zh: { ...zh },
  en: { ...en }
}

const langType = sessionStorage.getItem('lang') || 'en'

// 创建 VueI18n 实例
const i18n = new VueI18n({
  locale: langType,
  messages
})

export default i18n
