import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '@/views/about/index'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/index')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/index')
  }
]
const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
